<template>
  <div>
    <b-button v-if="permissions.includes('adicionar')" @click="$router.push('/configuracoes/tipo_perfil/novo')"
      >Adicionar</b-button
    >
    <Card class="mt-2">
      <b-table class="mt-2" small striped hover :items="profileTypes" :fields="fields">
        <template #cell(id)="data">
          <b-dropdown
            class="p-0 m-0 d-flex"
            size="md"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <fa-icon size="1x" icon="bars" />
            </template>
            <b-dropdown-item v-if="permissions.includes('editar')" @click="$router.push(`/configuracoes/tipo_perfil/${data.item.id}/editar`)"
              >Editar</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
        {
          key: "type",
          label: "Tipo",
          thStyle: {
            width: "95%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
      ],
    };
  },
  computed: {
    profileTypes() {
      return this.$store.state.configurations.profileTypes;
    },
    permissions() {
      let permissions = [];
      try {
        const response =
          this.$store.state.auth.burgerPermissions.configuracoes.tipo_de_perfil.habilitar;
        if (response) {
          permissions = response;
        }
      } catch (e) {}

      return permissions;
    },
  },
  mounted() {
    const unityId = this.$store.state.auth.currentUser.unity_id;
    this.$store.dispatch("configurations/profile_types_init", unityId);
  },
};
</script>

<style>
</style>